import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
// import * as styles from '../styles/home.module.css'
// import * as mobileStyles from '../styles/mobile/home.module.css'
import { useWindowDimensions } from '../components/Window'
import Seo from '../components/SEO'

const styles = {
  aligner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '4em',
    position: 'relative'
  },
  image: {
    float: 'right',
    marginTop: '50px',
    marginLeft: '25px',
    marginRight: '10px',
    height: '480px',
    width: '500px',
    objectFit: 'cover',
    border: '6px solid',
    borderRadius: '15px'
  },
  text: {
    width: '48em',
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '4em',
    paddingTop: '1em',
    lineHeight: '1',
    color: 'rgba(245, 221, 70, 1)'
  },
  textBody: {
    fontSize: '24px',
    lineHeight: '1.2',
    maxWidth: '90%',
    marginLeft: '5%',
    wordWrap: 'break-word',
    color: 'white',
    fontFamily: '"Lato-Regular", sans-serif',
    textAlign: 'center'
  }
}

const mobileStyles = {
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1em',
    height: '80%',
    width: '80%',
    objectFit: 'cover',
    border: '6px solid',
    borderRadius: '15px'
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center',
    marginBottom: '2em'
  },
  header: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '3em',
    paddingTop: '1em',
    lineHeight: '1',
    color: 'rgba(245, 221, 70, 1)'
  },
  textBody: {
    textAlign: 'left',
    fontSize: '1.5em',
    lineHeight: '1.2',
    maxWidth: '90%',
    marginLeft: '5%',
    wordWrap: 'break-word',
    color: 'white',
    fontFamily: '"Lato-Regular", sans-serif',
    textAlign: 'center'
  }
}

const Home = () => {
  const { width } = useWindowDimensions()

  if (width > 850)
    return (
      <Layout>
        <Seo title={`Welcome`} />
        <div style={styles.aligner}>
          <div style={styles.text}>
            <h1 style={styles.header}>
              Build your confidence as a Smart and Safe driver.
            </h1>
            <p style={styles.textBody}>
              If you are from Metro Vancouver, and are looking to get started with driving or if you want to refresh your skills, look no further.
              As a professional and experienced ICBC licenced Class 5/7 instructor,
              I will help you learn the proper driving techniques and build your confidence as a smart and safe driver.
            </p>
            <Link to="/rates">
              <button>
                Rates
              </button>
            </Link>
          </div>
          <img src="/fp.jpg" alt="prius front" style={styles.image} />
        </div>
      </Layout>
    )
  else
    return (
      <Layout>
        <Seo title={`Welcome`} />
        <img src="/fp.jpg" alt="prius front" style={mobileStyles.image} />
        <div style={mobileStyles.text}>
          <h1 style={mobileStyles.header}>
            Build your confidence as a Smart and Safe driver.
          </h1>
          <p style={mobileStyles.textBody}>
            If you are from Metro Vancouver, and are looking to get started with driving or if you want to refresh your skills, look no further.
            As a professional and experienced ICBC licenced Class 5/7 instructor,
            I will help you learn the proper driving techniques and build your confidence as a smart and safe driver.
          </p>
          <Link to="/rates">
            <button>
              Rates
            </button>
          </Link>
        </div>
      </Layout>
    )
}

export default Home
